<template>
  <PolicyPage title="Sponsorship Terms and Conditions" :data="data" />
</template>
"section": { "heading1": "Introduction", "paragraph": [ "This document details
privacy practices of ArcticCon IT Security Conference.·This policy applies to
information collected on this website and information collected during
events.·This document describes what information is collected, what information
may be shared with partners, and how you may correct or remove data." ] },
<script>
import data from "../../../public/policies/sponsorship-policy";
import PolicyPage from "@/components/policies/PolicyPage";

export default {
  name: "SponsorshipPolicy",
  props: {},
  components: {
    PolicyPage,
  },
  data: () => ({
    data,
  }),
  computed: {},
  methods: {},
};
</script>
